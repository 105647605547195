import React, { useEffect, useRef, useState } from 'react';
import Inteligo from './assets/img/Inteligo.jpg';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

export default function App() {
  const { register, handleSubmit, watch, getValues, reset, formState: { errors } } = useForm();
  const watchAllFields = watch();

  const captcha = useRef(null);
  const [recaptchaValid, setRecaptchaValid] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [mensajeLength, setMensajeLength] = useState(0);
  const [errorMessage, setErrorMessage] = useState(""); // Estado para almacenar el mensaje de error

  const onChange = () => {
    if (captcha.current.getValue()) {
      //console.log('El usuario no es un robot');
    }
  };

  const onSubmit = async () => {
    try {
      if (!captcha.current.getValue()) {
        setRecaptchaValid(false);
        return;
      }

      const formdata = getValues();

      // Limpiar espacios en blanco de los campos de nombre y apellido
      formdata.nombre = formdata.nombre.trim();
      formdata.apellido = formdata.apellido.trim();
      formdata.mensaje = formdata.mensaje.trim();

      // Validar que los campos no contengan solo espacios en blanco
      const areFieldsValid = Object.keys(formdata).every(key => key === "codigo" || formdata[key] !== '');

      // Validar que nombre y apellido no estén vacíos después de limpiar los espacios
      const nombreApellidoValid = formdata.nombre !== '' && formdata.apellido !== '' && formdata.mensaje !== '';

      if (!areFieldsValid || !nombreApellidoValid) {
        setErrorMessage('Por favor verifique, hay campos con espacios de teclado, sin texto, ingrese la información correcta');
        return;
      }

      const response = await axios.post(
        "https://prod-57.westus.logic.azure.com:443/workflows/4898eda35ef147a38957086009468200/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=diBxI1copq45F8pM3o2uKFC_ELA910U7zZaWWvpZMUI",
        formdata
      );

      console.log('Response:', response);
      reset();
      setFormSubmitted(true);
    } catch (error) {
      console.log('Error:', error);
    }
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => console.log(value, name, type));
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleMensajeChange = (event) => {
    setMensajeLength(event.target.value.length);
  };


  return (
    <div className="wrapper" style={{ maxWidth: '1030px', margin: '0 auto' }}>
      <div className="App font-sans bg-white min-h-screen">
        <div className="flex justify-center items-center py-[5rem] sm-425:py-0 ">
          <div className="max-w-[70rem]">
            <img src={Inteligo} alt="Inteligo portada" />
            <div className="bg-grisinteligo px-7 flex items-center p-2">
              <p className="mr-2 text-grisoscurointeligo text-base">
                <a href="https://www.inteligosab.com" className="text-grisoscurointeligo">Inicio</a>
              </p>
              <h1 className="text-azulinteligo text-2xl font-semibold text-base">
                <a href="/formulario" className="text-azulinteligo">| Formulario para la Atención de Pedidos, Quejas y/o Reclamos</a>
              </h1>
            </div>
            <div className="flex flex-col bg-[#fff] w-full px-7 sm-425:pt-16 sm-425:min-h-screen sm-375:min-h-screen sm-375:pt-16 sm-320:min-h-screen sm-320:pt-12">
              <div className="description mb-6 pt-6">
                <h2 className="text-azulinteligo mt-3 text-lg font-bold text-justify">Formulario para la Atención de Pedidos, Quejas y/o Reclamos</h2>
              </div>
              <div className="max-w-[50rem]">
                {formSubmitted ? (
                  <div className="text-center">
                    <h3 className="text-azulinteligo text-lg font-semibold mb-4">¡Gracias por llenar el formulario!</h3>
                    <p className="text-neutral-600">Su formulario ha sido enviado con éxito.</p>
                    <button
                      className="bg-azulinteligo text-white font-semibold py-2 px-12 mt-6 cursor-pointer hover:bg-slate-200 hover:text-azulinteligo active:bg-azulinteligo focus:outline-none focus:ring-1 focus:ring-gray-200"
                      onClick={() => { window.location.reload(); }}
                    >
                      Volver
                    </button>

                  </div>
                ) : (
                  <form action="#" onSubmit={handleSubmit(onSubmit)}>
                    <h3 className="text-azulinteligo mt-3 text-lg font-bold">Datos del cliente</h3>

                    <div className="flex flex-wrap justify-between">
                      {/* Campo Nombres */}
                      <div className="form__group flex flex-col mb-5 w-full sm:w-[48%]">
                        <label htmlFor="name" className="font-semibold text-neutral-600 pb-2">Nombres*</label>
                        {watchAllFields && (
                          <input
                            type="text"
                            id="nombre"
                            placeholder="Ingrese su nombre"
                            className="border-1 outline-none border-gray-400 focus:ring-1 focus:ring-gray-200 p-2"
                            {...register("nombre", { required: true, maxLength: 70, pattern: /^[a-zA-ZÁÉÍÓÚáéíóúäëïöüÄËÏÖÜÑñ ]+$/ })}
                            aria-invalid={errors.nombre ? "true" : "false"}
                          />
                        )}
                        {errors.nombre?.type && (
                          <span role="alert" className="text-red-500 text-sm">
                            {errors.nombre.type === "required" && " *Este campo es requerido"}
                            {errors.nombre.type === "pattern" && " Solo se permite texto en este campo."}
                          </span>
                        )}
                      </div>

                      {/* Campo Apellidos */}
                      <div className="form__group flex flex-col mb-5 w-full sm:w-[48%]">
                        <label htmlFor="name" className="font-semibold text-neutral-600 pb-2">Apellidos*</label>
                        {watchAllFields && (
                          <input
                            type="text"
                            id="apellido"
                            placeholder="Ingrese su apellido"
                            className="border-1 outline-none border-gray-400 focus:ring-1 focus:ring-gray-200 p-2"
                            {...register("apellido", { required: true, maxLength: 70, pattern: /^[a-zA-ZÁÉÍÓÚáéíóúäëïöüÄËÏÖÜÑñ ]+$/ })}
                            aria-invalid={errors.apellido ? "true" : "false"}
                          />
                        )}
                        {errors.apellido && (
                          <span role="alert" className="text-red-500 text-sm">
                            {errors.apellido.type === "required" && " *Este campo es requerido"}
                            {errors.apellido.type === "pattern" && " Solo se permite texto en este campo."}
                          </span>
                        )}
                      </div>

                    </div>
                    {/* Número de Identificación Personal*/}
                    <div className="flex flex-wrap justify-between">
                      <div className="form__group flex flex-col mb-5 w-full sm:w-[48%]">
                        <label htmlFor="name" className="font-semibold text-neutral-600 pb-2">Número de identificación personal*</label>
                        <input
                          type="text"
                          id="identificacion"
                          style={{
                            WebkitAppearance: 'textfield',
                            MozAppearance: 'textfield',
                            appearance: 'textfield',
                          }}
                          placeholder="Ingrese su número de identificación personal"
                          className="border-1 outline-none border-gray-400 focus:ring-1 focus:ring-gray-200 p-2"
                          {...register("identificacion", { required: true, pattern: /^[a-zA-Z0-9]+$/ })}
                          aria-invalid={errors.identificacion ? "true" : "false"}
                          onChange={(e) => {
                            if (e.target.value.length > 50) {
                              e.target.value = e.target.value.slice(0, 50);
                            }
                          }}
                        />
                        {errors.identificacion?.type === "pattern" && (
                          <span role="alert" className="text-red-500 text-sm">
                            Solo se permiten letras y números en este campo.
                          </span>
                        )}
                        {errors.identificacion?.type === "required" && (
                          <span role="alert" className="text-red-500 text-sm ">
                            *Este campo es requerido
                          </span>
                        )}
                      </div>
                      {/* Campo Código */}
                      <div className="form__group flex flex-col mb-5 w-full sm:w-[48%]">
                        <label htmlFor="name" className="font-semibold text-neutral-600 pb-2">Código del cliente</label>
                        {watchAllFields && (
                          <input
                            type="number"
                            min="1"
                            id="codigo"
                            style={{
                              WebkitAppearance: 'textfield', /* Para navegadores WebKit */
                              MozAppearance: 'textfield', /* Para Firefox */
                              appearance: 'textfield', /* Para otros navegadores */
                            }}
                            placeholder="Escriba su código"
                            className="border-1 outline-none border-gray-400 focus:ring-1 focus:ring-gray-200 p-2"
                            {...register("codigo", { maxLength: 70 })}
                            onChange={(e) => {
                              if (e.target.value.length > 100) {
                                e.target.value = e.target.value.slice(0, 100);
                              }
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-between">
                      {/* Campo Correo */}
                      <div className="form__group flex flex-col mb-5 w-full sm:w-[100%]">
                        <label htmlFor="correo" className="font-semibold text-neutral-600 pb-2">Correo electrónico*</label>
                        {watchAllFields && (
                          <input
                            type="email"
                            id="correo"
                            placeholder="Escriba su correo electrónico"
                            className="border-1 outline-none border-gray-400 focus:ring-1 focus:ring-gray-200 p-2"
                            {...register("correo", { required: true, pattern: /^\S+@\S+$/ })}
                            aria-invalid={errors.correo ? "true" : "false"}
                          />
                        )}
                        {errors.correo?.type === "required" && (
                          <span role="alert" className="text-red-500 text-sm">
                            *Este campo es requerido
                          </span>
                        )}
                      </div>
                    </div>

                    {/* Campo Mensaje */}
                    <h3 className="text-azulinteligo mt-3 text-lg font-bold">Detalles de la queja o reclamo</h3>
                    <div className="form__group flex flex-col mb-5">
                      <label htmlFor="name" className="font-semibold text-neutral-600 pb-2">Queja o reclamo*</label>
                      {watchAllFields && (
                        <>
                          <textarea
                            id="mensaje"
                            placeholder="Escriba su queja o reclamo con un máximo 4000 caracteres"
                            rows={8}
                            className="border-1 outline-none border-gray-400 focus:ring-1 focus:ring-gray-200 p-2"
                            {...register("mensaje", { required: true, maxLength: 4000 })}
                            aria-invalid={errors.mensaje ? "true" : "false"}
                            maxLength={4000}
                            onChange={handleMensajeChange}
                          />
                          <div className="text-left text-sm text-gray-500 font-semibold">{mensajeLength}/4000 caracteres máximo</div>
                        </>
                      )}
                      {errors.mensaje?.type === "required" && (
                        <span role="alert" className="text-red-500 text-sm">
                          *Este campo es requerido
                        </span>
                      )}

                    </div>

                    {/* Campo  Recaptcha */}
                    <div className="recaptcha mb-3">
                      <ReCAPTCHA
                        ref={captcha}
                        sitekey="6LddaP4pAAAAADVy3sd-uKaWfZ-UBzeqtZUTNr5o" //numero de conexión
                        //sitekey="6Lf9ScYpAAAAADJRTEtM1cA98k8i2KW8m4rOgF_Q" //Pruebas numero de conexión Local
                        onChange={onChange}
                      />
                      {!recaptchaValid && (
                        <span role="alert" className="text-red-500 text-sm font-semibold">
                          Por favor, acepte el ReCAPTCHA.
                        </span>
                      )}
                    </div>
                    {/* Mensaje de error */}
                    {errorMessage && (
                      <div className="text-red-500 text-sm font-semibold">
                        {errorMessage}
                      </div>
                    )}
                    {/* Botón de envio */}
                    <div className="form__group">
                      <input
                        type="submit"
                        value="Enviar"
                        className="bg-azulinteligo text-white font-semibold py-2 px-12  cursor-pointer hover:bg-slate-200 hover:text-azulinteligo active:bg-azulinteligo focus:outline-none focus:ring-1 focus:ring-gray-200"
                      />
                    </div>
                    <div>
                      <p className="text-sm pt-6 text-azulinteligo text-justify leading-5 font-normal">
                        La descripción del reclamo debe ser detallada lo suficiente para una mejor atención y respuesta.  Sugerimos responder a:
                        qué pasó, cuándo pasó, producto o servicio afectado, cuánto es el monto del reclamo. El código del cliente no es un dato
                        obligatorio, pero ayuda a identificar de forma precisa la persona que interpone el reclamo y las relaciones que mantiene
                        con el Banco. Inteligo Bank Ltd. se contactará con el cliente a través de los datos registrados en nuestras bases de
                        datos, luego de las validaciones necesarias. Inteligo Bank Ltd. ofrecerá una respuesta a la queja o reclamo, a través
                        del correo electrónico indicado en este formulario.

                      </p>
                      <p className="text-sm pt-6 text-azulinteligo text-justify leading-5 font-normal">
                        Al enviar este formulario, usted declara que ha leído y comprendido la Política de Privacidad y Protección de Datos Personales,
                        y que consiente expresamente el tratamiento de sus datos personales por parte de Inteligo Bank Ltd. para las finalidades indicadas
                        en la misma, la cual puede leer en detalle en el siguiente link <a href="https://www.inteligobank.com/politica-de-privacidad" className="font-bold">Política de Privacidad - Inteligo Bank</a>.
                      </p>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
